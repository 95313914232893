export const LABEL_UN_PARE_VAPEUR =
    "Un pare-vapeur ou tout autre dispositif permettant d'atteindre un résultat équivalent est mis en place, lorsqu'il est nécessaire de protéger les matériaux d'isolation thermique contre les transferts d'humidité pour garantir la performance de l'ouvrage.";
export const LABEL_AMENAGEMENTS_NECESSAIRES = "Aménagements nécessaires à la mise en place de l'isolation";
export const LABEL_ISOLATION_THERMIQUE = "L'isolation thermique réalisée a nécessité la mise en place d'un pare-vapeur ou tout autre dispositif permettant d'atteindre un résultat équivalent";
export const LABEL_TRAVAUX_EN_COMBLES_CHAUFFES = 'Travaux en combles chauffés et finitions réalisées.';

export const LABEL_ENERGIE_DE_CHAUFFAGE = 'énergie de chauffage';
export const LABEL_HEATING_TYPE_BEFORE_INSTALLATION = 'énergie de chauffage avant travaux';
export const LABEL_HEATING_TYPE_AFTER_INSTALLATION = 'énergie de chauffage après travaux';
export const LABEL_TOTAL_SURFACE_OF_WINDOWS = 'surface totale de fenêtres, doubles fenêtres, fenêtres de toiture ou portes-fenêtres posées';

export const LABEL_OPERATION_NE_CORRESPOND =
    "L’opération ne correspond ni à l'installation de fenêtres dans les parties communes non chauffées du bâtiment, ni à la fermeture d'une loggia par des parois vitrées, ni à la construction d’une véranda à parois vitrées, ni à la création d’une ouverture dans une paroi opaque, ni au remplacement de vitrages sur une fenêtre ou porte-fenêtre existante.";

export const LABEL_BATIMENT_RESIDENTIEL = 'Bâtiment résidentiel en France d’outre-mer';
export const LABEL_A_LA_REUNION = 'A La Réunion, le bâtiment ne se situe pas dans une zone au-dessus de 600 m d’altitude.';

export const LABEL_LA_POMPE_CHALEUR_EST_ASSOCIEE = 'La pompe à chaleur est associée à une chaudière à haute performance énergétique pour le chauffage des locaux';
export const LABEL_LA_POMPE_CHALEUR_EST_UTILISEE = "La pompe à chaleur est utilisée uniquement pour le chauffage de l'eau chaude sanitaire.";

export const LABEL_EQUIPEMENT_INSTALLE = 'Équipement installé';
export const LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_OU_GAZ = "L'équipement installé vient en remplacement d'une chaudière individuelle au charbon ou au gaz, autre qu'à condensation.";
export const LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_FIOUL = "L'équipement installé vient en remplacement d'une chaudière individuelle au fioul.";

export const LABEL_SURFACE_PAC = 'surface chauffée par la PAC installée';

export const LABEL_SURFACE_HABITABLE = 'surface habitable';
export const LABEL_NOMBRE_DE_LOGEMENTS_EQUIPES = "nombre de logements équipées d'une chaudière individuelle";

export const LABEL_TYPE_DE_VENTILLATION = 'Type de ventillation mécanique contrôlée';
export const LABEL_NOMBRE_DE_LOGEMENTS_DESSERVIS = 'nombre de logements desservis';

export const LABEL_LA_POMPE_A_CHALEUR_EST_UTILISEE = 'La pompe à chaleur est utilisée uniquement pour le chauffage de l’eau chaude sanitaire';
export const LABEL_LA_POMPE_A_CHALEUR_EST_CONCUE = 'La pompe à chaleur est conçue pour fonctionner à basse température';

export const LABEL_OPERATION_REALISEE_SUR_UN_RESEAU =
    "L’opération est réalisée sur un réseau hydraulique de chauffage ou d’eau chaude sanitaire existant depuis plus de 2 ans à la date d'engagement de l'opération";
export const LABEL_NON_ISOLE = 'non isolé';
export const LABEL_DONT_ISOLATION = 'dont l’isolation en place est de classe inférieure ou égale à 2';
export const LABEL_INSTALLATION_DE_CHAUFFAGE_COLLECTIF = "L'installation de chauffage collectif ou de production d'eau chaude sanitaire a été remplacée après le 1er janvier 2018.";

export const LABEL_OPERATION_ENGAGEE = 'L’opération est engagée moins de douze mois suivant l’engagement d’une opération portant sur un même bâtiment et un même bénéficiaire';

export const LABEL_BATIMENT_TERTIAIRE = 'Bâtiment tertiaire en France d’outre-mer';
export const LABEL_BUILDING_TOTAL_AREA = 'Surface totale du bâtiment (m²)';

export const LABEL_OPERATION_INCLUT_APPLICATION = "L’opération inclut l'application de peintures réfléchissantes sur la toiture";

export const LABEL_ISOLANT_EST_DE_CLASSE_SUP_3 = 'L’isolant est de classe supérieure ou égale à 3 selon la norme NF EN 12 828+A1:2014';
export const LABEL_ISOLANT_EST_DE_CLASSE_SUP_4 = 'L’isolant est de classe supérieure ou égale à 4 selon la norme NF EN 12 828+A1:2014';

export const LABEL_INSTALLATION_FINISHES = {
    DEFAULT: "Finitions d'installation",
    CUSTOM: "Autres finitions d'installation",
    QUOTATION: "Finitions proposées permettant d'assurer la fixation satisfaisante et la protection de l'isolant",
    INVOICE: "Finitions réalisées permettant d'assurer la fixation satisfaisante et la protection de l'isolant",
};

export const LABEL_EQUIPEMENT_REMPLACE_CHAUDIERE_CHARBON_FIOUL_OU_GAZ =
    'L’équipement à installer vient en remplacement d’une chaudière individuelle au charbon, au fioul ou au gaz, autres qu’à condensation';

export const LABEL_EQUIPEMENT_REMPLACE_POELE_CHARBON = "L'appareil à installer vient remplacer un poêle à charbon";

export const LABEL_CHAUDIERE_REMPLACE_CHAUDIERE_CHARBON_FIOUL_OU_GAZ = 'La chaudière à installer vient remplacer une chaudière individuelle au charbon, au fioul ou au gaz, autres qu’à condensation';

export const LABEL_PREVIOUS_HEATING_EQUIPMENT_FUNCTIONING_TYPE = "L'équipement à installer vient en remplacement d'une chaudière individuelle fonctionnant";

export const LABEL_SOLAR_PANEL_TOTAL_AREA = 'Surface des panneaux solaire (m²)';

export const LABEL_SELECT_INSTALLATION_TYPE = "Type d'installation";

export const LABEL_INPUT_LIVING_SPACE_AREA = 'Surface habitable (m²)';
export const LABEL_INPUT_SERVED_DWELLINGS_COUNT = 'Nombre de logements desservis';
export const LABEL_SURFACE_OUTSIDE_OF_INSTALLED_CAPTORS = 'Surface hors tout de capteurs installés';
export const LABEL_SELECT_AUXILIARY_HEATING_MODE = "Mise en place d'un chauffage d'appoint";
export const LABEL_ADDITIONNAL_DESCRIPTION = 'Description complémentaire';

export const LABEL_NOR_INSTALLATION_OF_HEATING_EQUIPMENT =
    'ni à l’installation d’équipements de chauffage ou de production d’eau chaude sanitaire consommant majoritairement du charbon, du fioul ou du gaz';
export const LABEL_NOR_AN_INCREASE_IN_GREENHOUSE_GAZ = 'ni à une hausse des émissions de gaz à effet de serre';

export const LABEL_SELECT_SALE_TYPE = 'Type de vente';
export const LABEL_INPUT_SOLAR_COLLECTORS_TOTAL_AREA = 'La superficie hors-tout des capteurs solaires selon la norme ISO 9488';
export const LABEL_CHECKBOX_SALE_BY_SUBSCRIPTION_CONTRACT =
    "Le contrat de vente par abonnement a une durée minimale de dix ans. Il comporte une clause de maintien, par le professionnel, du bon état de fonctionnement du CESI pendant toute la durée du contrat d'abonnement";

export const LABEL_LAMP_DEPOSIT_NUMBER = 'Nombre de luminaires déposés';
export const LABEL_DEPOSIT_LAMP_ARE_MORE_THAN_TWO_YEARS = "Les luminaires déposés sont existants depuis plus de 2 ans à la date d'engagement de l'opération";
export const LABEL_LAMP_ADDRESS_PRECISSION = 'Précision adresse';
export const LABEL_LAMP_STREET_TYPE = 'Type de voirie';
export const LABEL_REMOVE_OLD_INSULATION = "Dépose de l'ancien isolant";

export const PRIVATE_LIGHTING_TYPE = Object.freeze({
    RUES: 'Rues',
    AVENUES: 'Avenues',
    PARCS: 'Parcs',
    ALLEES: 'Allées',
    VOIES_PIETONNES: 'Voies piétonnes',
    PARKING: 'Parking',
});

export const PUBLIC_LIGHTING_TYPE = Object.freeze({
    AUTOROUTIER: 'Autoroutier',
    ROUTIER: 'Routier',
    URBAIN: 'Urbain',
});

export const LIGHTING_TYPE = 'Type d’éclairage';
export const LIGHTING_PUBLIC_TYPE = 'Eclairage public de type « fonctionnel »';
export const LIGHTING_AMBIANT_OR_PRIVATE_TYPE = 'Eclairage de type « ambiance » ou privé';

export const LIGHTING_TYPE_OPTIONS = Object.freeze({
    LIGHTING_PUBLIC_TYPE: { label: LIGHTING_PUBLIC_TYPE, value: 'LIGHTING_PUBLIC_TYPE' },
    LIGHTING_AMBIANT_OR_PRIVATE_TYPE: { label: LIGHTING_AMBIANT_OR_PRIVATE_TYPE, value: 'LIGHTING_AMBIANT_OR_PRIVATE_TYPE' },
});

// BAR TH 174
export const TECHNICAL_PATRIMONIAL_OR_ARCHITECTURAL_CONSTRAINT = 'Le bâtiment présente une contrainte technique, architecturale ou patrimoniale justifiée';
export const BIO_SOURCING_ISOLANT =
    'Dans le cas d’isolants bio-sourcés la résistance thermique est calculée conformément aux dispositions prévues dans l’annexe IX de l’arrêté du 26 octobre 2010 relatif aux caractéristiques thermiques et aux exigences de performance énergétique des bâtiments nouveaux et des parties nouvelles de bâtiments';

export const HEATING_SYSTEM_TYPE_WHERE_GREEHOUSE_GAS_SUP_150 =
    "Il est installé un système de chauffage ou de production d'eau chaude sanitaire qui inclut un ou plusieurs équipements pour lesquels le niveau d'émissions de gaz à effet de serre de chacun est supérieur à 150 gCO2eq/kWh PCI, et dont le taux de couverture, défini comme le rapport entre la quantité d’énergie fournie par l’ensemble des équipements dont les émissions sont supérieures à 150 gCO2eq/kWh PCI et les besoins annuels de chaleur couverts par le système est supérieur à 30%";

export const HEATING_SYSTEM_WHERE_GREENHOUSE_GAZ_SUP_300 =
    "Il est conservé un système de chauffage ou de production d'eau chaude sanitaire qui inclut un ou plusieurs équipements pour lesquels le niveau d'émissions de gaz à effet de serre de chacun est supérieur à 300 gCO2eq/kWh PCI, et dont le taux de couverture, défini comme le rapport entre la quantité d’énergie fournie par l’ensemble des équipements dont les émissions sont supérieures à 300 gCO2eq/kWh PCI et les besoins annuels de chaleur couverts par le système est supérieur à 30 %";

export const LABEL_HEATING_PUMP_ASSOCIATED_TO_ANOTHER_HEATING_SYSTEM = 'La pompe à chaleur est associée à un autre système de chauffage';
export const LABEL_HEATING_PUMP_ONLY_USED_FOR_WATER_HEATING = "La pompe à chaleur est utilisée uniquement pour le chauffage de l'eau chaude sanitaire";
export const LABEL_HEATING_PUMP_HEATED_AREA = 'Surface chauffée par la PAC installée';

export const LABEL_BOILER_MORE_THAN_THOW_YEARS_OLD = 'La chaudière est existante depuis plus de deux ans à la date d’engagement de l’opération';
export const LABEL_THERMOSTATIC_VALVES_MORE_THAN_TWO_YEARS_OLD =
    'Les robinets thermostatiques sont installés sur des radiateurs existants depuis plus de deux ans et raccordés à un système de chauffage central à combustible';

export const LABEL_HEATED_SURFACE = 'Surface chauffée (m²)';

export const LABEL_ROOMS_COUNT = "Nombre de pièces du logement équipées d'un émetteur de chauffage";
export const LABEL_OPERATION_TYPE = "Type d'opération";
export const LABEL_OPERATION_TYPE_QUESTION = "Le système de régulation vient en remplacement d'un système de régulation déjà existant";
export const LABEL_INSTALLED_EQUIPMENT_COUNT = "Nombre d'équipements installés";
export const LABEL_HEAT_EMITTER_TYPE = "Type d'émetteur de chaleur";
export const LABEL_REGULATION_SYSTEM_IS_AT_MOST_OF_CLASS_C = "Le système de régulation existant avant l'opération est au plus de classe C";
export const LABEL_REGULATION_SYSTEM_CLASS = 'Classe du dispositif installé selon la norme NF EN ISO 52120-1';
export const LABEL_REGULATION_SYSTEM_IS_INSTALLED_IN_EVERY_ROOM_THAT_HAVE_A_HEAT_EMITTER =
    "Le système de régulation est installé dans toutes les pièces du logement équipées d'un émetteur de chaleur, sans exception";
export const LABEL_REGULATION_SYSTEM_INCLUDES_CENTRAL_SYSTEM =
    "Le système comporte un appareil central qui collecte les informations des dispositifs déportés dans les pièces équipées d'un émetteur de chaleur, pilote ces dispositifs, gère la programmation d'intermittence et les consignes de températures associée";
export const LABEL_REGULATION_SYSTEM_INCLUDES_PROBE = "Le système dispose d'une sonde de mesure de la température ambiante dans chaque pièce équipées d'un émetteur de chaleur";
export const LABEL_REGULATION_SYSTEM_SETTINGS_ARE_LOCALLY_UPDATABLE =
    "Tous les réglages, y compris les programmations horaires par pièce et l'exécution de ces programmes, peuvent s'effectuer en local a minima";
export const LABEL_REGULATION_SYSTEM_RUNS_OFFLINE = 'Le dispositif de régulation continue de fonctionner en cas de perte de connexion Internet';
export const LABEL_REGULATION_SYSTEM_TEMPERATURE_IS_UPDATABLE_FOR_EACH_ROOM = 'La température de chaque pièce du logement peut être ajustée';
export const LABEL_HOUSE_CONTAINS_HEATED_FLOORS_OR_CEILINGS = 'Le logement comporte des planchers ou des plafonds chauffants';
export const LABEL_REGULATION_SYSTEM_REGULATION_IS_THE_FINEST_TECHNICALLY_POSSIBLE =
    'La régulation est mise en oeuvre selon le maillage le plus fin techniquement atteignable sauf si le logement a une surface inférieure à 35m²';
export const LABEL_REGULATION_SYSTEM_INCLUDES_REGULATOR = "Le système possède un dispositif régulant l'émission de chaque émetteur de chaleur selon la consigne émise par l'appareil central";
export const LABEL_REGULATION_SYSTEM_EQUIPMENT_CAN_HELP_DEROGATE_FROM_INSTRUCTIONS = "Les équipements permettent à l'occupant de déroger localement à toutes les consignes énoncées";
export const LABEL_REGULATION_SYSTEM_CENTRAL_SYSTEM_INCLUDES_API = "L'API est intégrée à la solution/ au service. (Il n'est pas nécessaire que le signal soit affiché dans l'interface de pilotage.)";
export const LABEL_REGULATION_SYSTEM_INSTALLED_ON_AIR_AIR_SYSTEM = "Le système de régulation n'est pas installé sur un système de gainage air/air";
export const LABEL_REGULATION_SYSTEM_SIGNAL_ORIGIN = 'Le système récupère en temps réel le signal';

export const LABEL_BOILER_CAPTORS_GLAZED_REQUIREMENT =
    "Le chauffe-eau solaire individuel (CESI) installé est à circulation forcée et comprend des capteurs solaires thermiques vitrés, un ballon d'eau chaude solaire et un appoint";
export const LABEL_BOILER_COVERS_WATER_HEATING_REQUIREMENT = 'Le chauffe-eau solaire permet de couvrir la totalité du besoin en eau chaude sanitaire du logement';
export const LABEL_BOILER_CAPTORS_TOTAL_AREA = 'Surface hors-tout totale de capteurs solaires thermiques vitrés installés';
