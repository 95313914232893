import { LABEL_EFFICACITE_ENERGETIQUE, LABEL_PUISSANCE_THERMIQUE, LABEL_CLASSE_REGULATEUR } from '../../product-constants';
import { LABEL_INPUT_REGULATOR_BRAND, LABEL_INPUT_REGULATOR_REFERENCE } from '../../form_labels';

export default [
    {
        property: LABEL_EFFICACITE_ENERGETIQUE,
        label: 'Efficacité énergétique saisonnière selon le règlement (EU) n°813/2013 de la commission du 2 août 2013',
        type: 'number',
        required: true,
    },
    {
        property: LABEL_PUISSANCE_THERMIQUE,
        label: 'Puissance thermique nominale',
        type: 'string',
        required: true,
    },
    {
        property: LABEL_INPUT_REGULATOR_BRAND,
        label: LABEL_INPUT_REGULATOR_BRAND,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_INPUT_REGULATOR_REFERENCE,
        label: LABEL_INPUT_REGULATOR_REFERENCE,
        type: 'string',
        required: true,
    },
    {
        property: LABEL_CLASSE_REGULATEUR,
        label: 'Classe du régulateur telle que définie au paragraphe 6.1 de la communication de la Commission 2014/C 207/02 dans le cadre du règlement (UE) n°813/2013.',
        type: 'string',
        required: true,
    },
];
